import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PageTitle from "../components/PageTitle";
import BlockContentImage from "../components/BlockContentImage";

const DonatePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Donate"
        keywords={[`health`, `fitness`, `donate`, `support us`]}
      />
      <PageTitle image={data.settings.headerImage.asset.fixed} title="Donate" />
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        <section className="mb-12">
          <h1 className="text-heading">Why Donate?</h1>
          <div>
            <BlockContent
              blocks={data.settings.reason || []}
              serializers={{
                types: {
                  captionedImage: BlockContentImage,
                },
              }}
              projectId={process.env.GATSBY_SANITY_ID}
              dataset={process.env.GATSBY_SANITY_DATASET}
            />
          </div>
        </section>
        <section>
          <h1 className="text-heading">Donate Link</h1>
          <a href={data.settings.link} title="Donate">
            <img
              src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_SbyPP_mc_vs_dc_ae.jpg"
              alt="Donate"
              className="-mt-1 border border-link px-3 bg-white"
            />
          </a>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DonatePageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderDonate {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
      link: donateLink
      reason: _rawDonateReason
    }
  }
`;

export default DonatePage;
